import moment from "moment";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { TStatistics } from "../../api/statistics/types";
import { TStatisticsCard } from "./types";
import {
  cardBuilder,
  companiesCard,
  companySubscriptionsUpdate,
  deviceDataCards,
  driversStatsCard,
  subscriptionQuantity,
  userDataCards,
  vehicleAndDriversCard,
} from "./utils";

interface AppState {
  activeOption: { title: string; value: string } | null;
  startDate: Date | null;
  endDate: Date | null;
  driverData: TStatisticsCard | null;
  driverStatsData: TStatisticsCard | null;
  vehicleData: TStatisticsCard | null;
  vehicleStatsData: TStatisticsCard | null;
  companyData: TStatisticsCard | null;
  subscriptionData: TStatisticsCard | null;
  subscriptionCompanyData: TStatisticsCard | null;
  userData: TStatisticsCard | null;
  deviceData: TStatisticsCard | null;
  activeTabIndex: number;
  topCount: number;
  setActiveOption: (option: { title: string; value: string }) => void;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  setDriverData: (data: TStatistics) => void;
  setVehicleData: (data: TStatistics) => void;
  setCompanyData: (data: TStatistics) => void;
  setSubscriptionData: (data: TStatistics) => void;
  setSubscriptionCompanyData: (data: TStatistics) => void;
  setUserData: (data: TStatistics) => void;
  setDeviceData: (data: TStatistics) => void;
  resetStore: () => void;
  isLoadingData: boolean;
  setIsLoadingData: (isLoading: boolean) => void;
  handleTabChange: (index: number) => void;
  setTopCount: (num: number) => void;
}

const useStore = create<AppState>()(
  devtools((set) => ({
    activeOption: { title: "Week", value: "week" },
    startDate: moment().startOf("day").subtract(1, "week").toDate(),
    endDate: moment().endOf("day").toDate(),
    driverData: null,
    driverStatsData: null,
    vehicleData: null,
    vehicleStatsData: null,
    companyData: null,
    subscriptionData: null,
    subscriptionCompanyData: null,
    userData: null,
    deviceData: null,
    isLoadingData: false,
    activeTabIndex: 0,
    topCount: 5,
    setActiveOption: (option: { title: string; value: string }) =>
      set({ activeOption: option }),
    setStartDate: (date: Date | null) => set({ startDate: date }),
    setEndDate: (date: Date | null) => set({ endDate: date }),
    setDriverData: (data: TStatistics) => {
      const drData: TStatisticsCard = cardBuilder(
        data,
        "Drivers",
        "Total drivers",
        vehicleAndDriversCard(data),
        ["activeTotal", "inactiveTotal", "newTotal"],
      );
      const drStatsData: TStatisticsCard = cardBuilder(
        data,
        "Activities",
        "",
        driversStatsCard(data),
        ["activeUniqueActionTotal", "inactiveUniqueActionTotal", "activeActionTotal", "inactiveActionTotal"],
      );
      set({
        driverData: drData || null,
        driverStatsData: drStatsData || null,
      });
    },
    setVehicleData: (data: TStatistics) => {
      const vehData: TStatisticsCard = cardBuilder(
        data,
        "Vehicles",
        "Total vehicles",
        vehicleAndDriversCard(data),
        ["activeTotal", "inactiveTotal", "newTotal"],
      );
      const vehStatsData: TStatisticsCard = cardBuilder(
        data,
        "Activities",
        "",
        driversStatsCard(data, true),
        ["activeUniqueActionTotal", "inactiveUniqueActionTotal", "activeActionTotal", "inactiveActionTotal"],
      );
      set({
        vehicleData: vehData || null,
        vehicleStatsData: vehStatsData || null,
      });
    },
    setCompanyData: (data: TStatistics) => {
      const cpData: TStatisticsCard = cardBuilder(
        data,
        "Companies",
        "Total companies",
        companiesCard(data),
        [
          "activeTotal",
          "inactiveTotal",
          "trialTotal",
          "newTotal",
          "cancelledTotal",
        ],
      );
      set({
        companyData: cpData || null,
      });
    },
    setSubscriptionData: (data: TStatistics) => {
      const cpData: TStatisticsCard = cardBuilder(
        data,
        "Subscription Quantity",
        "Total quantity",
        subscriptionQuantity(data),
        ["increaseTotal", "deacreaseTotal", "updateTotal"],
      );
      set({
        subscriptionData: cpData || null,
      });
    },
    setSubscriptionCompanyData: (data: TStatistics) => {
      const cpData: TStatisticsCard = cardBuilder(
        data,
        "Company subscription updates",
        "Total subscription updated companies",
        companySubscriptionsUpdate(data),
        ["increaseCompanyTotal", "decreaseCompanyTotal", "updateCompanyTotal"],
      );
      set({
        subscriptionCompanyData: cpData || null,
      });
    },
    setUserData: (data: TStatistics) => {
      const cpData: TStatisticsCard = cardBuilder(
        data,
        "User Engagement Metrics",
        "Total active users",
        userDataCards(data),
        [
          "loginTotal",
          "logoutTotal",
          "acceptedTotal",
          "rejectedTotal",
          "newTotal",
        ],
      );
      set({
        userData: cpData || null,
      });
    },
    setDeviceData: (data: TStatistics) => {
      const cpData: TStatisticsCard = cardBuilder(
        data,
        "App Users",
        "Total active users",
        deviceDataCards(data),
        [
          "androidTotal",
          "iosTotal",
          "mobileTotal",
          "tabletTotal",
        ],
      );
      set({
        deviceData: cpData || null,
      });
    },
    setIsLoadingData: (isLoading: boolean) => {
      set({ isLoadingData: isLoading });
    },
    handleTabChange: index => set({ activeTabIndex: index }),
    setTopCount: (num: number) =>
      set({ topCount: num }),
    resetStore: () =>
      set({
        activeOption: { title: "Week", value: "week" },
        startDate: moment().startOf("day").subtract(1, "week").toDate(),
        endDate: moment().endOf("day").toDate(),
      }),
  })),
);

export default useStore;
