import moment from "moment";
import { TStatistics } from "../../api/statistics/types";
import { TCard, TStatisticsCard } from "./types";

export const selectColor = (el: string): string => {
  const colorMap: Record<string, string> = {
    inactiveTotal: "#F99E2C",
    inactiveActionTotal: "#F99E2C",
    inactiveUniqueActionTotal: "#F99E2C",
    activeTotal: "#27AE60",
    loginTotal: "#9B51E0",
    acceptedTotal: "#27AE60",
    activeUniqueActionTotal: "#27AE60",
    trialTotal: "#797B8D",
    increaseTotal: "#27AE60",
    increaseCompanyTotal: "#27AE60",
    rejectedTotal: "#F64747",
    newTotal: "#409CFF",
    updateTotal: "#409CFF",
    updateCompanyTotal: "#409CFF",
    androidTotal: "#3DDC84",
    iosTotal: "#037BFF",
    mobileTotal: "#F99E2C",
    tabletTotal: "#9B51E0",
  };

  return colorMap[el] ?? (el.includes("active") ? "#27AE60" : "#F99E2C");
};
const cardLabels = {
  total: "Total",
  activeTotal: "Active",
  inactiveTotal: "Inactive",
  trialTotal: "Free trails",
  increaseTotal: "Increases",
  deacreaseTotal: "Decreases",
  increaseCompanyTotal: "Increases",
  decreaseCompanyTotal: "Decreases",
  loginTotal: "Log ins",
  logoutTotal: "Log outs",
  newTotal: "New registered",
  acceptedTotal: "Approved",
  rejectedTotal: "Rejected",
  updateTotal: "Total updates",
  updateCompanyTotal: "Total updated companies",
  activeUniqueActionTotal: "Unique activated vehicles",
  inactiveUniqueActionTotal: "Unique deactivated vehicles",
  activeActionTotal: "Total activations",
  inactiveActionTotal: "Total deactivations",
  androidTotal: "Android total",
  iosTotal: "IOS total",
  mobileTotal: "Mobile total",
  tabletTotal: "Tablet total",
  cancelledTotal: "Cancelled"
};
export const cardBuilder = (
  data: TStatistics,
  title: string,
  headerTitle: string,
  cards: TCard[],
  chartKeys: string[],
): TStatisticsCard => {
  return {
    title,
    header: {
      title: headerTitle,
      total: parseFloat(data.total.toFixed(1)),
      newTotal: parseFloat(data.totalDifference.toFixed(1)),
      newPercentage: parseFloat(data.totalPercentage.toFixed(1)),
      statResultText: "",
    },
    cards,
    chart: {
      lines: !data?.allData?.length
        ? []
        : Object.keys(data?.allData[0]?.data)
            ?.filter(el => {
              if (chartKeys.includes(el)) {
                return el;
              }
            })
            ?.map(el => {
              const color = selectColor(el);
              return {
                key: el,
                color,
              };
            }),
      data: data.allData?.map((el, i) => {
        const firsData = data.allData[0];
        let date = moment(el.date).format("ddd, DD MMM");
        if (
          i &&
          firsData &&
          moment(firsData.date).diff(moment(el.date), "month")
        ) {
          date = moment(el.date).format("DD, MMM");
        }
        if (
          i &&
          firsData &&
          moment(firsData.date).diff(moment(el.date), "year")
        ) {
          date = moment(el.date).format("DD, MMM, YYYY");
        }
        return {
          ...el.data,
          name: date,
          title: date,
          cardLabels,
        };
      }),
    },
  };
};

export const vehicleAndDriversCard = (data: TStatistics): TCard[] => {
  return [
    {
      title: "New",
      total: parseFloat(data.newTotal.toFixed(1)),
      newPercentage: parseFloat(data.newPercentage.toFixed(1)),
      newTotal: parseFloat(data.newDifference.toFixed(1)),
      color: "blue",
      background: "rgba(64, 156, 255, 0.10)",
    },
    {
      title: "Active",
      total: parseFloat(data.activeTotal.toFixed(1)),
      newPercentage: parseFloat(data.activePercentage.toFixed(1)),
      newTotal: parseFloat(data.activeDifference.toFixed(1)),
      color: "green",
      background: "var(--Light-mode-Grey-01)",
    },
    {
      title: "Inactive",
      total: parseFloat(data.inactiveTotal.toFixed(1)),
      newPercentage: parseFloat((data.inactivePercentage || 0).toFixed(1)),
      newTotal: parseFloat(data.inactiveDifference.toFixed(1)),
      color: "orange",
      background: "var(--Light-mode-Grey-01)",
    },
  ];
};


export const driversStatsCard = (data: TStatistics, isVeh?: true): TCard[] => {
  return [
    {
      title: `Unique activated ${isVeh ? "vehicles" : "drivers"}`,
      total: parseFloat((data.activeUniqueActionTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.activeUniqueActionPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.activeUniqueActionDifference || 0).toFixed(1)),
      color: "green",
      background: "rgba(64, 156, 255, 0.10)",
    },
    {
      title: `Unique deactivated ${isVeh ? "vehicles" : "drivers"}`,
      total: parseFloat((data.inactiveUniqueActionTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.inactiveUniqueActionPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.inactiveUniqueActionDifference || 0).toFixed(1)),
      color: "orange",
      background: "var(--Light-mode-Grey-01)",
    },
    {
      title: "Total activations",
      total: parseFloat((data.activeActionTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.activeActionPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.activeActionDifference || 0).toFixed(1)),
      color: "green",
      background: "var(--Light-mode-Grey-01)",
    },
    {
      title: "Total deactivations",
      total: parseFloat((data.inactiveActionTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.inactiveActionPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.inactiveActionDifference || 0).toFixed(1)),
      color: "orange",
      background: "var(--Light-mode-Grey-01)",
    },
  ];
};

export const companiesCard = (data: TStatistics): TCard[] => {
  return [
    {
      title: "Active companies",
      total: parseFloat(data.activeTotal.toFixed(1)),
      newPercentage: parseFloat(data.activePercentage.toFixed(1)),
      newTotal: parseFloat(data.activeDifference.toFixed(1)),
      color: "green",
      background: "var(--Light-mode-Grey-01)",
    },
    {
      title: "Inactive companies",
      total: parseFloat(data.inactiveTotal.toFixed(1)),
      newPercentage: parseFloat((data.inactivePercentage || 0).toFixed(1)),
      newTotal: parseFloat(data.inactiveDifference.toFixed(1)),
      color: "orange",
      background: "rgba(249, 158, 44, 0.1)",
    },
    {
      title: "New companies",
      total: parseFloat(data.newTotal.toFixed(1)),
      newPercentage: parseFloat(data.newPercentage.toFixed(1)),
      newTotal: parseFloat(data.newDifference.toFixed(1)),
      color: "blue",
      background: "rgba(64, 156, 255, 0.10)",
    },
    {
      title: "Cancelled companies",
      total: parseFloat((data.cancelledTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.cancelledPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.cancelledDifference || 0).toFixed(1)),
      color: "red",
      background: "rgba(246, 71, 71, 0.1)",
    },
    {
      title: "Free trials",
      total: parseFloat((data.freeTrialTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.freeTrialPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.freeTrialDifference || 0).toFixed(1)),
      color: "grey",
      background: "rgba(245, 245, 248, 1)",
    },
  ];
};

export const subscriptionQuantity = (data: TStatistics): TCard[] => {
  return [
    {
      title: "Total updates",
      total: parseFloat((data.updateTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.updatePercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.updateDifference || 0).toFixed(1)),
      color: "blue",
      background: "rgba(64, 156, 255, 0.10)",
    },
    {
      title: "Increases",
      total: parseFloat((data.increaseTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.increasePercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.increaseDifference || 0).toFixed(1)),
      color: "green",
      background: "rgba(39, 174, 96, 0.1)",
    },
    {
      title: "Decreases",
      total: parseFloat((data.decreaseTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.decreasePercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.decreaseDifference || 0).toFixed(1)),
      color: "orange",
      background: "rgba(249, 158, 44, 0.1)",
    },
  ];
};

export const companySubscriptionsUpdate = (data: TStatistics): TCard[] => {
  return [
    {
      title: "Total updated companies",
      total: parseFloat((data.updateTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.updatePercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.updateDifference || 0).toFixed(1)),
      color: "blue",
      background: "rgba(64, 156, 255, 0.10)",
    },
    {
      title: "Increases",
      total: parseFloat((data.increaseTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.increasePercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.increaseDifference || 0).toFixed(1)),
      color: "green",
      background: "rgba(39, 174, 96, 0.1)",
    },
    {
      title: "Decreases",
      total: parseFloat((data.decreaseTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.decreasePercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.decreaseDifference || 0).toFixed(1)),
      color: "orange",
      background: "rgba(249, 158, 44, 0.1)",
    },
  ];
};

export const userDataCards = (data: TStatistics): TCard[] => {
  return [
    {
      title: "New registered",
      total: parseFloat((data.newTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.newPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.newDifference || 0).toFixed(1)),
      color: "blue",
      background: "rgba(64, 156, 255, 0.10)",
    },
    {
      title: "Approved",
      total: parseFloat((data.acceptedTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.acceptedPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.acceptedDifference || 0).toFixed(1)),
      color: "green",
      background: "#27AE601A",
    },
    {
      title: "Rejected",
      total: parseFloat((data.rejectedTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.rejectedPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.rejectedDifference || 0).toFixed(1)),
      color: "red",
      background: "rgba(246, 71, 71, 0.10)",
    },
    {
      title: "Log ins",
      total: parseFloat((data.loginTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.loginPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.loginDifference || 0).toFixed(1)),
      color: "purple",
      background: "var(--Light-mode-Grey-01)",
    },
    {
      title: "Log outs",
      total: parseFloat((data.logoutTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.logoutPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.logoutDifference || 0).toFixed(1)),
      color: "orange",
      background: "var(--Light-mode-Grey-01)",
    },
  ];
};

export const deviceDataCards = (data: TStatistics): TCard[] => {
  return [
    {
      title: "Android total",
      total: parseFloat((data.androidTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.androidPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.androidDifference || 0).toFixed(1)),
      cssColor: "#3DDC84",
      background: "rgba(61, 220, 132, 0.10)",
    },
    {
      title: "IOS total",
      total: parseFloat((data.iosTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.iosPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.iosDifference || 0).toFixed(1)),
      cssColor: "#037BFF",
      background: "rgba(3, 123, 255, 0.10)",
    },
    {
      title: "Mobile total",
      total: parseFloat((data.mobileTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.mobilePercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.mobileDifference || 0).toFixed(1)),
      cssColor: "#F99E2C",
      background: "rgba(249, 158, 44, 0.10)",
    },
    {
      title: "Tablet total",
      total: parseFloat((data.tabletTotal || 0).toFixed(1)),
      newPercentage: parseFloat((data.tabletPercentage || 0).toFixed(1)),
      newTotal: parseFloat((data.tabletDifference || 0).toFixed(1)),
      cssColor: "#9B51E0",
      background: "rgb(155, 81, 224, 0.10)",
    },
  ];
};
