import React from "react";
import "./style.scss";
import TextCaption from "../typography/TextCaption";
import TextTitle from "../typography/TextTitle";
import Icon from "../Icon";

interface IInfoCard {
  color?: "green" | "orange" | "blue" | "red" | "grey" | "purple";
  cssColor?: string;
  background?: string;
  title?: string;
  amount?: string | number;
  percent?: string;
  percentText?: string;
  total?: number;
  isLoading?: boolean;
}

const InfoCardSkeleton = () => {
  return (
    <div className='infoCard skeleton'>
      <div className='infoCard__title'>
        <div className='infoCard__title--indicator skeleton__indicator'></div>
        <div className='skeleton__text skeleton__text--caption'></div>
      </div>
      <div className='skeleton__text skeleton__text--title'></div>
      <div className='d-flex gap-1'>
        <div className='skeleton__icon'></div>
        <div className='skeleton__text skeleton__text--caption'></div>
        <div className='skeleton__text skeleton__text--caption'></div>
      </div>
    </div>
  );
};

const InfoCard = ({
  color,
  background,
  title,
  amount,
  percent,
  percentText = "",
  total,
  isLoading,
  cssColor
}: IInfoCard) => {
  return isLoading ? (
    <InfoCardSkeleton />
  ) : (
    <div className='infoCard' style={{ background }}>
      <div className='infoCard__title'>
        <div
          className={`infoCard__title--indicator infoCard__title--${color}`}
          style={cssColor ? { background: cssColor } : {}}
        ></div>
        <TextCaption level='3'>{title}</TextCaption>
      </div>
      <TextTitle level='1'>{amount}</TextTitle>
      <div className='d-flex gap-1'>
        {total && total > 0 ? (
          <Icon icon='arrow-trending-filled' />
        ) : total === 0 ? (
          <Icon icon='arrow-right-filled' />
        ) : (
          <Icon icon='arrow-trending-filled-red' />
        )}
        <TextCaption
          level='2'
          className={`color-${total && total > 0 ? "green" : total === 0 ? "silver" : "red"}`}
        >
          {percent}
        </TextCaption>
        <TextCaption level='3'>{percentText}</TextCaption>
      </div>
    </div>
  );
};

export default InfoCard;
