import React from "react";
import { Label, Input, FormGroup } from "reactstrap";

interface Option {
  label: string;
  value: string | number;
}

interface SelectProps {
  label?: string;
  options: Option[];
  value: string | number;
  onChange: (value: string | number) => void;
  disabled?: boolean;
}

const Select: React.FC<SelectProps> = ({ label, options, value, onChange, disabled }) => {
  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <Input
        type="select"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Input>
    </FormGroup>
  );
};

export default Select;
