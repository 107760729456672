import { useQuery } from "react-query";
import { statistics } from "../../api/statistics";
import { TTopDevicesRequest } from "../../api/statistics/types";

export const useTopDevicesList = (request: TTopDevicesRequest) => {  
  return useQuery(
    [
      "statistics",
      request,
    ],
    () =>
      statistics.topDevices(request),
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    },
  );
};
