import React, { useState } from "react";
import { useTopDevicesList } from "../../../../hooks/statistics/useTopDevicesList";
import { useGlobalStore } from "../../../../store";
import { TableColumn } from "../../../common/Table/TableTypes";
import Table from "../../../common/Table/Table";
import Select from "../../../common/form/Select";
import useStore from "../../store";
import TextTitle from "../../../common/ui/typography/TextTitle";
import { TOSType, TStatisticType } from "../../../../api/statistics/types";

export const TopDevicesTable = ({
  title,
  OSType,
  statisticType,
}: {
  title: string;
  OSType: TOSType;
  statisticType: TStatisticType;
}) => {
  const [topCount, setTopCount] = useState(5);
  const selectedCompany = useGlobalStore(state => state.selectedCompany);
  // const topCount = useStore(state => state.topCount);
  // const setTopCount = useStore(state => state.setTopCount);
  const { data, isLoading, isFetching }: any = useTopDevicesList({
    eldType: selectedCompany,
    OSType,
    statisticType,
    topCount,
  });

  const columns: TableColumn<{ data: string; count: number; num: number }>[] = [
    { header: "#", accessor: "num" },
    {
      header: "Data",
      accessor: "data",
    },
    {
      header: "Count",
      accessor: "count",
    },
  ];
  const options = [
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "All", value: 100000000 },
  ];
  return (
    <div style={{ minWidth: "300px", maxWidth: "100%", flex: "1 1 300px" }}>
      <div className='d-flex flex-column gap-2 align-items-baseline'>
        <TextTitle level='1'>{title}</TextTitle>
        <div style={{ width: 200 }}>
          <Select onChange={e => setTopCount(+e)} options={options} value={'' + topCount} />
        </div>
      </div>

      {(
        <div style={{ height: 261, overflowY: 'scroll' }}>
          <Table
            columns={columns}
            data={data?.data || []}
            renderRow={(item, column, rowIndex) => {
              if (column.accessor === "num") {
                return <>{rowIndex + 1}</>;
              }
              return item[column.accessor];
            }}
            isLoading={isLoading || isFetching}
          />
        </div>
      )}
    </div>
  );
};
