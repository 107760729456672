import axiosInstance from "../../axios";
import { TTopDevicesRequest } from "./types";

export const statistics = {
  list: async (startDate: string, endDate: string, selectedCompany: string, statisticType = '') => {
    try {
      const response = await axiosInstance.get(
        `/statistics/all-statistics-by-eld?startDate=${startDate}&endDate=${endDate}&eldType=${selectedCompany}&statisticType=${statisticType}`,
      );

      return response.data;
    } catch (error) {
      console.error("List error:", error);
      throw error;
    }
  },
  financialMetrics: async (selectedCompany: string) => {
    try {
      const response = await axiosInstance.get(
        `/statistics/today-by-eld?eldType=${selectedCompany}`,
      );

      return response.data;
    } catch (error) {
      console.error("List error:", error);
      throw error;
    }
  },
  topDevices: async (request: TTopDevicesRequest): Promise<{ data: string; count: number }> => {
    try {
      const response = await axiosInstance.get(
        `/statistics/top-devices-by-eld`, {
          params: request
        }
      );

      return response.data;
    } catch (error) {
      console.error("List error:", error);
      throw error;
    }
  }
};
